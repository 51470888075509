export interface FieldMeta {
  name: string;
  label?: string;
  hint?: string;
}

export const taxonomyFields: FieldMeta[] = [
  {name: "code", label: "Taxonomy CODE", hint: "全局编码"},
  {name: "id", label: "List ID", hint: "本名录ID"},
  {name: "latin", label: "Scientific Name", hint: "拉丁学名"},
  {name: "english", label: "English Name", hint: "英文名字"},
  {name: "chinese", label: "Chinese Name", hint: "简体名字"},
  {name: "chinesePinyin", label: "Chinese Pinyin", hint: "简体拼音"},
  {name: "chineseTone", label: "Chinese Tone", hint: "简体音调"},
  {name: "traditional", label: "Traditional Name", hint: "繁体名字"},
  {name: "traditionalPinyin", label: "Traditional Pinyin", hint: "繁体拼音"},
  {name: "traditionalTone", label: "Traditional Tone", hint: "繁体音调"},
  {name: "authority", label: "Authority", hint: "发现者"},
  {name: "url", label: "URL", hint: "参考链接"},
  {name: "code", label: "CODE", hint: "关联代码"},
  {name: "note", label: "Notes", hint: "备注信息"},
]

