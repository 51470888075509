






















































































import Vue from "vue";
import {Route} from "vue-router";
import {RouteNext} from "@/types";
import {Component} from 'vue-property-decorator';
import {vxm} from "@/store";
import {taxonomyFields} from "@/types/consts";

@Component
export default class TaxonomyPage extends Vue {

  private FIELDS = taxonomyFields;

  private taxonomyPage = vxm.taxonomyStore.taxonomyPage;


  private more = [
    {title: '图片', url: 'actions/images'},
    {title: '声音', url: 'actions/sounds'},
    {title: '辩识', url: 'actions/portrait'},
  ];

  private get urlBase(): string {
    return `/list/${this.$route.params.list}/${this.$route.params.node}`;
  }

  private get inDetail(): boolean {
    return this.$route.name === 'TaxonomyPage';
  }

  private get inEditing(): boolean {
    return this.$route.name === 'TaxonomyEdit';
  }

  private prevSpecies() {
    const allNodes = (this.$route.name == "TaxonomyPortraits" || this.$route.name == "TaxonomyPortrait") ?
        vxm.taxonomyStore.allPortraits : vxm.taxonomyStore.allSpecies;

    const index = allNodes.findIndex(s => s.node == +this.$route.params.node)
    if (index > 0) {
      const nextNode = allNodes[index - 1].node;

      this.$router.push({
        name: this.$route.name == "TaxonomyPortrait" ? "TaxonomyPortraits" : this.$route.name!,
        params: Object.assign({}, this.$route.params, {node: nextNode})
      })
    }
  }

  private nextSpecies() {

    const allNodes = (this.$route.name == "TaxonomyPortraits" || this.$route.name == "TaxonomyPortrait") ?
        vxm.taxonomyStore.allPortraits : vxm.taxonomyStore.allSpecies;

    const index = allNodes.findIndex(s => s.node == +this.$route.params.node)
    if (index >= 0 && index < allNodes.length - 1) {
      const nextNode = allNodes[index + 1].node;

      this.$router.push({
        name: this.$route.name == "TaxonomyPortrait" ? "TaxonomyPortraits" : this.$route.name!,
        params: Object.assign({}, this.$route.params, {node: nextNode})
      })
    }
  }

  private beforeRouteEnter(to: Route, from: Route, next: RouteNext) {
    // console.log('beforeRouteEnter', to, from);

    if (from.params.node != to.params.node) {
      vxm.taxonomyStore.fetchTaxonomy({
        listId: +to.params.list,
        nodeId: +to.params.node,
        fields: ["self", "ancestors", "children"]
      });
    }
    next();
  }

  private beforeRouteUpdate(to: Route, from: Route, next: RouteNext) {
    // console.log('beforeRouteUpdate', to, from);
    if (from.params.node != to.params.node) {
      vxm.taxonomyStore.fetchTaxonomy({
        listId: +to.params.list,
        nodeId: +to.params.node,
        fields: ["self", "ancestors", "children"]
      });
    }
    next();
  }

}
